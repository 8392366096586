import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

export const getAddressDataByCP = async (cp) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/catalogo/direccion?cp="+cp,
      config
    );
    
    return serverResponse.data

};
